import {
    useMediaQuery
} from '@/common/useMediaQuery.js';

export function updateRootFontSize() {
    const query = '(max-width: 1024px)';
    const isMobile = useMediaQuery(query).value;

    const baseSize = 16; // 基准字体大小
    const minFontSize = 12; // 最小字体大小
    const maxFontSize = 16; // 最大字体大小
    const designWidth = isMobile ? 440 : 1920; // 根据是否为移动端调整设计稿宽度
    const clientWidth = document.documentElement.clientWidth; // 当前窗口的宽度
    const scale = clientWidth / designWidth; // 计算当前窗口宽度与设计稿宽度的比例
    let newSize = baseSize * scale;

    // 限制字体大小在最小和最大范围内
    if (newSize < minFontSize) newSize = minFontSize;
    if (newSize > maxFontSize) newSize = maxFontSize;

    // 设置根元素的字体大小
    document.documentElement.style.fontSize = `${newSize}px`;
}
// useResponsiveFont.js
export const setupFontResizeListener = () => {
    const resizeListener = () => {
        updateRootFontSize();
    };

    window.addEventListener('resize', resizeListener);

    // 页面卸载时移除监听器
    return () => {
        window.removeEventListener('resize', resizeListener);
    };
};