import { createApp } from 'vue';
import pinia from '/@/stores/index';
import App from '/@/App.vue';
import router from '/@/router';
import { directive } from '/@/directive/index';
import { i18n } from '/@/i18n/index';
import other from '/@/utils/other';
import 'amfe-flexible'; // 引入 lib-flexible
import '@/common/flexible.js';
import ElementPlus from 'element-plus';
import '/@/theme/index.scss';
import '/@/assets/iconfont1/iconfont.css';
import '/@/assets/iconfont2/iconfont.css';
import customElButton from '/@/components/customElButton.vue';
import VueGridLayout from 'vue-grid-layout';
import iconFont from '@/components/iconFont.vue';
import { createHead } from '@unhead/vue';

const app = createApp(App);
const head = createHead();
app.use(head);

app.component('iconFont', iconFont);
app.component('customElButton', customElButton);
directive(app);
other.elSvg(app);

app.use(pinia).use(router).use(ElementPlus).use(i18n).use(VueGridLayout).mount('#app');
