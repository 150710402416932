<template>
    <i :class="iconClass" :style="iconStyle"></i>
  </template>

  <script setup>
  import { computed,defineProps } from 'vue';

  // 接收图标名称和其他样式作为 props
  const props = defineProps({
    name: {
      type: String,
      required: true
    },
    size: {
      type: [String, Number],
      default: '16px'
    },
    color: {
      type: String,
      default: 'inherit'
    }
  });

  // 计算图标的 class
  const iconClass = computed(() => {
    return `iconfont ${props.name}`;
  });

  // 计算图标的样式
  const iconStyle = computed(() => {
    return {
      fontSize: props.size,
      color: props.color
    };
  });
  </script>

  <style scoped>
  /* 确保 iconfont 的样式已加载 */
  .iconfont {
    font-family: 'iconfont' !important; /* 或根据你的图标库实际的字体名称 */
  }
  </style>
