<template>
	<el-config-provider :size="getGlobalComponentSize" :locale="getGlobalI18n">
		<!-- <template v-if="!isIndexRoute">
			<userSide></userSide>
			<userCenter></userCenter>
		</template> -->
		<router-view/>
	</el-config-provider>
</template>

<script setup lang="ts" name="app">
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useTagsViewRoutes } from '/@/stores/tagsViewRoutes';
import { useThemeConfig } from '/@/stores/themeConfig';

import other from '/@/utils/other';
import { Local, Session } from '/@/utils/storage';
import mittBus from '/@/utils/mitt';
import setIntroduction from '/@/utils/setIconfont';
import { updateRootFontSize, setupFontResizeListener } from '@/common/useResponsiveFont.js';

const route = useRoute();
const { messages, locale } = useI18n();
const stores = useTagsViewRoutes();
const storesThemeConfig = useThemeConfig();
const { themeConfig } = storeToRefs(storesThemeConfig);

const setingsRef = ref();

// 判断当前路由是否是 '/index'
const isIndexRoute = computed(() => route.path === '/index');

// 锁屏逻辑
const setLockScreen = computed(() => {
	return themeConfig.value.isLockScreen ? themeConfig.value.lockScreenTime > 1 : themeConfig.value.lockScreenTime >= 0;
});

// 获取版本逻辑
const getVersion = computed(() => {
	let isVersion = false;
	if (route.path !== '/login') {
		if ((Local.get('version') && Local.get('version') !== __NEXT_VERSION__) || !Local.get('version')) {
			isVersion = true;
		}
	}
	return isVersion;
});

// 获取全局组件大小
const getGlobalComponentSize = computed(() => {
	return other.globalComponentSize();
});

// 获取全局 i18n
const getGlobalI18n = computed(() => {
	return messages.value[locale.value];
});

// 挂载前的设置
onBeforeMount(() => {
	setIntroduction.cssCdn();
	setIntroduction.jsCdn();
});

// 挂载时的设置
onMounted(() => {
	nextTick(() => {
		updateRootFontSize();
		setupFontResizeListener();
		mittBus.on('openSetingsDrawer', () => {
			setingsRef.value.openDrawer();
		});
		if (Local.get('themeConfig')) {
			storesThemeConfig.setThemeConfig({ themeConfig: Local.get('themeConfig') });
			document.documentElement.style.cssText = Local.get('themeConfigStyle');
		}
		if (Session.get('isTagsViewCurrenFull')) {
			stores.setCurrenFullscreen(Session.get('isTagsViewCurrenFull'));
		}
	});
});

// 组件销毁时，关闭监听布局配置/i18n监听
onUnmounted(() => {
	setupFontResizeListener(); // 确保在组件卸载时移除监听器
	mittBus.off('openSetingsDrawer', () => {});
});

// 监听路由变化，设置网站标题
watch(
	() => route.path,
	() => {
		other.useTitle();
	},
	{
		deep: true,
	}
);
</script>
