<template>
	<el-button v-bind="$attrs" :style="customStyles" @click="onClick" :disabled="disabled">
		<!-- 如果传入了图标，显示图标 -->
		<template v-if="icon">
			<icon-font :name="icon" :style="iconStyles" class="button-icon" :color="iconColor"></icon-font>
		</template>

		<!-- 按钮内容 -->
		<slot />
	</el-button>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';

const props = defineProps({
	icon: {
		type: String,
		required: false,
	},
	padding: {
		type: String,
		default: '10px 20px',
	},
	borderRadius: {
		type: String,
		default: '4px',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	textColor: {
		type: String,
		default: '',
	},
	iconColor: { // 新增的 iconColor prop
		type: String,
		default: '#fff', // 默认情况下跟随 textColor
	},
});

const emits = defineEmits(['click']);

const customStyles = computed(() => ({
	padding: props.padding,
	borderRadius: props.borderRadius,
	color: props.textColor,
}));

const iconStyles = computed(() => ({
	color:  '#fff' || props.iconColor || props.textColor , // 优先使用 iconColor，如果未设置则使用 textColor
}));

const onClick = (event) => {
	if (!props.disabled) {
		emits('click', event);
	}
};
</script>

<style scoped lang="scss">
.el-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.button-icon {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
